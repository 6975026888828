<script setup lang="ts">
import { useStore } from 'vuex'

const store = useStore()
const auth = useAuth()
const isOnline = useOnline()

let syncInterval: any

const scrollElement = ref<HTMLElement | undefined>(undefined)
const scrollPosition = ref(0)

const navMobileVisible = computed(() => store.getters.uix?.mobile !== undefined && !!store.getters.uix?.mobile)
const navDesktopVisible = computed(() => store.getters.uix?.mobile !== undefined && (!store.getters.uix?.mobile && !store.getters.ui.sideMenuOpen))
const containerClasses = computed(() => ({
  'mobile': store.getters.uix?.mobile,
  'navigation': (navDesktopVisible.value || navMobileVisible.value) && !store.getters.uix?.inputActive && !store.getters.uix?.dialogOpen, // && !store.getters.uix?.fullscreenMode,
  'sidemenu-open': auth.loggedIn && store.getters.ui?.sideMenuOpen,
  'collapse-dreams': !!store.getters.deviceSettings?.collapse_dreams_in_lists,
  'collapse-bullets': !!store.getters.deviceSettings?.collapse_bullets_in_lists,
}))
const containerStyles = computed(() => ({
  '--scroll': scrollPosition.value.toString(),
}))

const onScroll = useThrottleFn(() => {
  if (scrollElement.value?.scrollTop !== undefined) {
    scrollPosition.value = scrollElement.value.scrollTop
  }
}, 50, true)
function sync() {
  store.dispatch('syncData')
    .catch((error: any) => {
      console.log('Sync data error:', error)
      if (error.name === 'ExpiredAuthSessionError') {
        navigateTo('/logout')
      }
    })
}
function initSyncInterval() {
  syncInterval = setInterval(() => sync, 60 * 1000)
}
function clearSyncInterval() {
  if (syncInterval) {
    clearInterval(syncInterval)
  }
}
function onVisibilityChange() {
  if (store.getters.profileSettings?.action_sync_enabled) {
    if (document.visibilityState === 'visible') {
      console.log('[visible]')
      sync()
      initSyncInterval()
    }
    else {
      console.log('[invisible]')
      clearSyncInterval()
    }
  }
}

useEventListener(document, 'visibilitychange', onVisibilityChange)

watch(() => isOnline.value, (val: boolean) => {
  if (store.getters.profileSettings?.action_sync_enabled) {
    if (val === true) {
      sync()
      initSyncInterval()
    }
    else {
      clearSyncInterval()
    }
  }
})

onMounted(() => {
  onScroll()

  // Reset sync for the case that it got stuck.
  store.dispatch('resetSync')

  // Sync on page load right away and then start the sync interval,
  // unless the device is offline.
  if (isOnline.value) {
    sync()
    if (store.getters.profileSettings?.action_sync_enabled) {
      initSyncInterval()
    }
  }
})
onBeforeUnmount(() => {
  clearSyncInterval()
})
</script>

<template>
  <div id="page-container" :class="containerClasses" :style="containerStyles">
    <AppNavMobile v-if="navMobileVisible" />
    <AppNavDesktop v-if="navDesktopVisible" />
    <AppMenu />
    <div
      id="page-content"
      ref="scrollElement"
      @scroll.passive="onScroll"
    >
      <main>
        <div class="content-container">
          <!--
            DO NOT wrap the Nuxt outlet into a v-if,
            otherwise page-level head hooks won't work!
          -->
          <slot />
        </div>
      </main>
    </div>
    <AppIndicatorDisplay />
    <AppDialogs />
    <AppShortcuts />
  </div>
</template>

<style lang="scss" scoped>
:deep(.vue-portal-target) {
  width: 35rem;
  margin: 0 auto;
}
:deep(#page-content) {
  @media #{$desktop} {
    width: calc(100vw - 2 * #{$menu-size});
    padding: 0 $menu-size;
  }
  .sidemenu-open & {
    @media #{$desktop} {
      width: calc(100vw - 2 * #{$menu-size} - #{$sidemenu-size});
      margin-left: $sidemenu-size;
      main {
        // Note: This is a hack. Setting padding-right on #page-content inside
        // .sidemenu-open somehow doesn't work (probably a bug).
        padding-right: $sidemenu-size;
      }
      /* &::-webkit-scrollbar {
        width: 1rem;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: hsl(var(--text-faint));
        border: .3rem solid transparent;
        background-clip: content-box;
        &:hover {
          background-color: hsl(var(--text-dim));
        }
      } */
    }
  }
}
</style>
