<script setup lang="ts">
import { useStore } from 'vuex'

const store = useStore()

const profileSettings = computed(() => store.getters.profileSettings)
const classes = computed(() => ({ 'auto-hide': !!store.getters.deviceSettings?.auto_hide_navigation }))
const menuItemsTop = computed(() => {
  if (profileSettings.value) {
    return [
      {
        to: '/write',
        label: 'Today',
        title: 'Write',
        icon: 'write',
        class: 'write-item',
      },
      {
        to: '/journal',
        label: 'Journal',
        title: 'Journal',
        icon: 'journal',
        exact: true,
      },
      {
        hidden: !store.getters.profile?.internal || !profileSettings.value.planning_enabled,
        to: '/look-ahead',
        label: 'Look ahead',
        title: 'Look ahead',
        icon: 'look-ahead',
      },
      {
        hidden: !profileSettings.value?.reflect_enabled,
        to: '/reflect',
        label: 'Reflect',
        title: 'Reflect',
        icon: 'reflect',
      },
      {
        hidden: (!store.getters.pro && store.getters.profile?.internal),
        to: '/insights',
        label: 'Insights',
        title: 'Insights',
        icon: 'insights',
      },
    ]
  }
  return []
})
const menuItemsBottom = computed(() => {
  if (profileSettings.value) {
    return [
      {
        hidden: !profileSettings.value.dreams_enabled,
        to: '/dreams',
        label: 'Dreams',
        title: 'Dreams',
        icon: 'dream',
        color: 'hsl(var(--dream))',
      },
      {
        to: '/highlights',
        label: 'Highlights',
        title: 'Highlights',
        icon: 'highlight',
        exact: true,
      },
      {
        to: '/tags',
        label: 'Tags',
        title: 'Tags',
        icon: 'tag',
      },
      {
        to: '/people',
        label: 'People',
        title: 'People',
        icon: 'mention',
      },
      {
        hidden: !profileSettings.value.notes_enabled,
        to: '/notes',
        label: 'Notes',
        title: 'Notes',
        icon: 'note',
      },
      {
        hidden: !profileSettings.value.goals_enabled,
        to: '/focus',
        label: 'Focus',
        title: 'Focus',
        icon: 'goal',
      },
      {
        hidden: !profileSettings.value.gems_enabled,
        to: '/wisdom',
        label: 'Wisdom',
        title: 'Wisdom',
        icon: 'gem',
      },
      {
        hidden: !profileSettings.value.ideas_enabled,
        to: '/ideas',
        label: 'Ideas',
        title: 'Ideas',
        icon: 'idea',
      },
    ]
  }
  return []
})

function toggleSideMenu() {
  store.dispatch('updateUI', {
    sideMenuOpen: !store.getters.ui.sideMenuOpen,
  })
}
</script>

<template>
  <div v-show="!store.getters.ui.sideMenuOpen" class="navigation" :class="classes">
    <div class="nav-header">
      <AccountDropdown orientation="right" />
    </div>
    <div class="nav-content">
      <div class="nav-top">
        <template v-for="(item, idx) in menuItemsTop" :key="idx">
          <AppNavItem
            v-if="!item.hidden"
            :to="item.to"
            :label="item.label"
            :title="item.title"
            :icon="item.icon"
            :class="item.class"
            :exact="item.exact"
          />
        </template>
      </div>
      <div class="divider" />
      <div class="nav-bottom">
        <template v-for="(item, idx) in menuItemsBottom" :key="idx">
          <AppNavItem
            v-if="!item.hidden"
            :to="item.to"
            :label="item.label"
            :title="item.title"
            :icon="item.icon"
            :exact="item.exact"
          />
        </template>
      </div>
    </div>
    <div class="nav-footer">
      <div class="menu-btn-container">
        <a class="menu-btn" title="Expand menu" @click="toggleSideMenu()">
          <Icon name="fa6-solid:angles-right" stroke-width="4" />
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.navigation {
  @include noselect;
  position: fixed;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  left: 0;
  width: $menu-size;
  height: 0;
  z-index: 9001;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 0;
  &.auto-hide {
    @media #{$desktop} {
      .nav-header,
      .nav-content {
        opacity: 0;
        transition: opacity 0.15s ease-in-out;
      }
      &:hover {
        .nav-header,
        .nav-content {
          opacity: 1;
        }
      }
    }
  }
  .nav-header {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: $menu-size;
    min-height: $menu-size;
    :deep(.action-btn) {
      width: $button-size;
      height: $button-size;
      color: hsl(var(--text-faint)) !important;
      font-size: $font-size-icon !important;
      &:hover {
        color: hsl(var(--text-dim)) !important;
      }
    }
  }
  .nav-content {
    @include hidescrollbar;
    overflow: auto;

    // Scroll shadow covers:
    background: linear-gradient(hsl(var(--base)) 30%, hsla(var(--base), 0)), linear-gradient(hsla(var(--base), 0), hsl(var(--base)) 70%) 0 100%, radial-gradient(farthest-side at 50% 0, hsla(var(--text), 0.15), hsla(var(--text), 0)), radial-gradient(farthest-side at 50% 100%, hsla(var(--text), 0.15), hsla(var(--text), 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: hsl(var(--base));
    background-size: 100% 1rem, 100% 1rem, 80% .4rem, 80% .4rem;
    background-position-x: 50%, 50%, 50%, 50%;
    background-attachment: local, local, scroll, scroll;

    .nav-top,
    .nav-bottom {
      display: flex;
      flex-flow: column;
      justify-content: center;
      box-sizing: border-box;
      & > * {
        :deep(svg) {
          font-size: $font-size-icon;
        }
      }
    }
    .nav-top {
      margin-top: .75rem;
    }
    .nav-bottom {
      margin-bottom: .75rem;
    }
  }
  .nav-footer {
    height: $menu-size;
    min-height: $menu-size;
    .menu-btn-container {
      flex: 1 0;
      display: flex;
      height: $menu-size;
      margin-bottom: 0 !important;
      justify-content: center;
      align-items: center;
      width: $menu-size;
      height: $menu-size;
      .menu-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% - 2rem);
        min-width: 3rem;
        height: calc(100% - .5rem);
        font-size: $font-size-icon-mobile;
        // transition: background .3s ease, color .3s ease;
        @media #{$desktop} {
          border-radius: $border-radius-round;
          width: 2.5rem;
          height: 2.5rem;
          min-width: 2.5rem;
          color: hsl(var(--text-faint)) !important;
          font-size: $font-size-icon;
          &:hover {
            color: hsl(var(--text-dim)) !important;
          }
        }
      }
    }
  }
}
.divider {
  height: 1.5rem;
  min-height: 1.5rem;
}
// Fix issue with TWA.
// When the app opens it attaches a ?... to the URL so that it doesn't match
// exactly. Solution is to only for not-exact matches on the write item bc
// otherwise there are problems with not exact highlighting elsewhere.
:deep(.write-item) {
  a.active {
    // @media #{$desktop} {
    //   background-color: hsl(var(--element));
    // }
    .icon,
    .label {
      color: hsl(var(--text));
    }
  }
}
</style>
