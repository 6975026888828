<script setup lang="ts">
import { useStore } from 'vuex'

const route = useRoute()
const auth = useAuth()
const store = useStore()

const profile = computed(() => store.getters.profile)
const profileSettings = computed(() => store.getters.profileSettings)
const mobile = computed(() => !!store.getters.uix.mobile)
const pro = computed(() => !!profile.value?.pro)
const internal = computed(() => !!profile.value?.internal)
const isOpen = computed(() => auth.loggedIn && store.getters.ui.sideMenuOpen)
const showJournalInNavigation = computed(() => !!internal.value || !!store.getters.deviceSettings?.show_journal_in_navigation)
const mainLinks = computed(() => ([
  {
    to: '/write',
    icon: 'write',
    iconClass: 'journal-color',
    label: 'Write',
    title: 'Write about your day',
  },
  {
    to: '/journal',
    icon: 'journal',
    iconClass: 'journal-color',
    label: 'Journal',
    title: 'All your entries',
    hidden: mobile.value && showJournalInNavigation.value,
  },
  {
    to: '/look-ahead',
    icon: 'look-ahead',
    iconClass: 'journal-color',
    label: 'Look ahead',
    title: '',
    hidden: !internal.value || !profileSettings.value?.planning_enabled,
  },
  {
    to: '/reflect',
    icon: 'reflect',
    iconClass: 'journal-color',
    label: 'Reflect',
    title: 'Look back with hindsight',
    hidden: !profileSettings.value?.reflect_enabled,
  },
  {
    to: '/insights',
    icon: 'insights',
    iconClass: 'journal-color',
    label: 'Insights',
    title: 'Statistics and patterns',
    hidden: (internal.value && !pro.value),
  },
]))
const visibleMainLinks = computed(() => mainLinks.value.filter(link => !link.hidden))
const moduleLinks = computed(() => {
  if (profile.value && profileSettings.value) {
    return [
      {
        to: '/journal',
        icon: 'journal',
        iconClass: 'journal-color',
        label: 'Journal',
        title: 'All your entries',
        hidden: !internal.value || showJournalInNavigation.value,
      },
      {
        to: '/dreams',
        icon: 'dream',
        iconClass: 'dream-color',
        label: 'Dreams',
        title: 'Your nightly adventures',
        hidden: !profileSettings.value.dreams_enabled,
      },
      {
        to: '/highlights',
        icon: 'highlight',
        iconClass: 'highlight-color',
        label: 'Highlights',
        title: 'The moments that make life worth living',
      },
      {
        to: '/tags',
        icon: 'tag',
        iconClass: 'tag-color',
        label: 'Tags',
        title: 'The things you do',
      },
      {
        to: '/people',
        icon: 'mention',
        iconClass: 'person-color',
        label: 'People',
        title: 'The people in your life',
      },
      {
        to: '/notes',
        icon: 'note',
        iconClass: 'note-color',
        label: 'Notes',
        title: 'A space to take notes and write freely',
        hidden: !profileSettings.value.notes_enabled,
      },
      {
        to: '/focus',
        icon: 'goal',
        iconClass: 'focus-color',
        label: 'Focus',
        title: 'Establish what is truly important',
        hidden: (internal.value && !pro.value) || !profileSettings.value.beta || !profileSettings.value.goals_enabled,
      },
      {
        to: '/wisdom',
        icon: 'gem',
        iconClass: 'quote-color',
        label: 'Wisdom',
        title: 'Collect shower thoughts, inspiring quotes, mind-blowing facts, excerpts from good books, and lessons learned',
        hidden: !profileSettings.value.gems_enabled,
      },
      {
        to: '/ideas',
        icon: 'idea',
        iconClass: 'idea-color',
        label: 'Ideas',
        title: 'All your ideas in one place',
        hidden: !profileSettings.value.ideas_enabled,
      },
    ]
  }
  return []
})
const visibleModuleLinks = computed(() => moduleLinks.value.filter(link => !link.hidden))

function closeMenu() {
  store.dispatch('updateUI', { sideMenuOpen: false })
}
function closeMenuMobile() {
  // Only close menu on mobile.
  if (mobile.value) {
    setTimeout(() => {
      closeMenu()
    }, 100) // Original: 150ms
  }
}

watch(() => route.path, () => closeMenuMobile())
</script>

<template>
  <div
    class="sidemenu"
    :class="{ open: isOpen }"
  >
    <!-- Actions -->
    <div class="sidemenu-actions">
      <div v-if="internal" class="account-indicators">
        <Icon v-if="pro" name="sparkle" />
        <span>{{ pro ? 'PRO' : 'Upgrade' }}</span>
      </div>
      <!-- <NuxtLink
        v-if="mobile && (pro || !internal)"
        to="/insights"
        title="Insights"
        class="action avoid-highlight"
        @contextmenu.prevent
      >
        <Icon name="insights" />
      </NuxtLink> -->
      <!-- <NuxtLink
        v-if="!pro && internal"
        to="/account/pro"
        class="get-pro-btn"
      >
        <small><u>Get PRO</u></small>
      </NuxtLink> -->
      <AccountDropdown :orientation="mobile ? 'left' : 'right'" />
    </div>
    <!-- Content -->
    <div class="sidemenu-content">
      <!-- <div v-if="!mobile" class="element-divider top" /> -->
      <div v-if="!mobile" class="links">
        <NuxtLink
          v-for="(link, idx) in visibleMainLinks"
          :key="idx"
          class="link"
          :to="link.to"
          :title="link.title"
          @contextmenu.prevent
        >
          <div class="link-content">
            <span class="icon-container">
              <Icon :name="link.icon" :class="link.iconClass" />
            </span>
            <span class="label">
              {{ link.label }}
            </span>
          </div>
        </NuxtLink>
      </div>
      <div v-if="!mobile" class="element-divider" />
      <div
        v-if="visibleModuleLinks"
        class="links"
      >
        <NuxtLink
          v-for="(link, idx) in visibleModuleLinks"
          :key="idx"
          class="link"
          :to="link.to"
          :title="link.title"
          @contextmenu.prevent
        >
          <div class="link-content">
            <span class="icon-container">
              <Icon :name="link.icon" :class="link.iconClass" />
            </span>
            <span class="label">
              {{ link.label }}
              <!-- <BadgeBeta v-if="link.beta" /> -->
            </span>
          </div>
        </NuxtLink>
      </div>
    </div>
    <!-- Navigation -->
    <div v-if="!mobile" class="sidemenu-footer">
      <a
        title="Minimize menu"
        class="action"
        @contextmenu.prevent
        @click="closeMenu()"
      >
        <Icon :name="isOpen ? (mobile ? 'cross' : 'fa6-solid:angles-left') : 'fa6-solid:bars'" />
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sidemenu {
  display: none;
  position: fixed;
  top: calc(0px + env(safe-area-inset-top));
  left: 0;
  width: 0;
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  height: calc(var(--vh, 1vh) * 100 - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  background: hsl($sidemenu-bg-desktop-hsl);
  overscroll-behavior: contain !important;
  transition: height .25s ease;
  z-index: 12;
  @media #{$mobile}, #{$tablet} {
    height: calc(100vh - #{$menu-size-mobile} - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    height: calc(var(--vh, 1vh) * 100 - #{$menu-size-mobile} - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    background: hsl($sidemenu-bg-mobile-hsl);
  }
  &.open {
    display: flex;
    flex-flow: column;
    width: 100vw;
    @media #{$desktop} {
      justify-content: space-between;
      width: $sidemenu-size;
    }
  }
  .sidemenu-content {
    @include hidescrollbar;
    overflow: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    width: 100%;
    max-width: $content-max-width;
    margin: 0 auto;
    box-sizing: border-box;
    transition: height .25s ease;
    @media #{$mobile}, #{$tablet} {
      height: calc(100vh - 4rem - #{$menu-size-mobile} - env(safe-area-inset-bottom));
      height: calc(var(--vh, 1vh) * 100 - 2 * #{$menu-size-mobile} - env(safe-area-inset-bottom));
    }
    @media #{$desktop} {
      // Scroll shadow covers:
      background: linear-gradient(hsl($sidemenu-bg-desktop-hsl) 30%, hsla($sidemenu-bg-desktop-hsl, 0)), linear-gradient(hsla($sidemenu-bg-desktop-hsl, 0), hsl($sidemenu-bg-desktop-hsl) 70%) 0 100%, radial-gradient(farthest-side at 50% 0, hsla(var(--text), 0.2), hsla(var(--text), 0)), radial-gradient(farthest-side at 50% 100%, hsla(var(--text), 0.2), hsla(var(--text), 0)) 0 100%;
      background-repeat: no-repeat;
      background-color: hsl($sidemenu-bg-desktop-hsl);
      background-size: 100% 1rem, 100% 1rem, 100% .5rem, 100% .5rem;
      background-position-x: 50%, 50%, 50%, 50%;
      background-attachment: local, local, scroll, scroll;
    }

    .links {
      flex-grow: 1;
      display: flex;
      flex-flow: column;
      justify-content: center;
      // &:first-child {
      //   margin-top: .75rem;
      // }
      // &:last-child {
      //   margin-bottom: .75rem;
      // }
      .link {
        @include noselect;
        // @include background-transition(250ms);
        position: relative;
        width: calc(100% - 4rem);
        margin: 0 1rem;
        padding: .5rem 1rem;
        color: hsl(var(--text-dim));
        font-size: 1.2rem;
        font-weight: normal;
        border-radius: .65rem;
        cursor: pointer;
        @media #{$desktop} {
          width: calc(100% - 2.2rem);
          margin: 0 .5rem; // .1rem .5rem;
          padding: .4rem .6rem;
          font-size: 1.1rem;
        }
        &.active {
          // background-color: hsl(var(--item));
          color: hsl(var(--text));
          font-weight: bold;
          .icon-container {
            border-radius: .5rem !important;
            // transition: border-radius 1s ;
          }
        }
        &.exact-active {
          cursor: default;
        }
        &:not(.active) {
          @media (hover: hover) {
            &:hover {
              // background-color: hsl(var(--element));
              color: hsl(var(--text));
              /* .icon-container {
                border-radius: .5rem !important;
              } */
            }
          }
        }
        .link-content {
          display: flex;
          align-items: center;
          .icon-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2rem;
            height: 2rem;
            background-color: hsl(var(--grayscale-hue), var(--grayscale-saturation), 15%);
            font-size: 1rem;
            border-radius: 1rem;
            transition: border-radius .2s ease-in-out;
            @media #{$desktop} {
              width: 1.8rem;
              height: 1.8rem;
              font-size: .8rem;
              border-radius: .9rem;
            }
            .theme--dark & {
              background-color: hsl(var(--grayscale-hue), var(--grayscale-saturation), 28%);
            }
          }
          .label {
            flex-grow: 1;
            margin-left: 1rem;
            box-sizing: border-box;
            border-top: 2px solid transparent;
            border-bottom: 2px solid transparent;
            @media #{$mobile}, #{$tablet} {
              font-size: 1.35rem;
            }
            @media #{$desktop} {
              margin-left: .65rem;
            }
          }
        }
      }
    }
    .element-divider {
      height: 2px;
      min-height: 2px;
      max-width: 3.5rem;
      margin: calc((1.5rem - 2px) / 2) 1.1rem;
      background-color: hsl(var(--item));
      &.top {
        margin-top: 0;
      }
      &.bottom {
        margin-bottom: 0;
      }
    }
  }
  .sidemenu-actions,
  .sidemenu-footer {
    display: flex;
    justify-content: flex-end;
    padding: 0 .5rem;
    align-items: center;
    height: $menu-size-mobile;
    min-height: $menu-size-mobile;
    box-sizing: border-box;
    @media #{$mobile}, #{$tablet} {
      border-top: 1px solid hsl(var(--border));
    }
    @media #{$desktop} {
      height: $menu-size;
      min-height: $menu-size;
      padding: 0 .75rem;
    }
    .dropdown-container {
      @media #{$desktop} {
        margin-left: 0 !important;
      }
    }
    .action {
      display: flex;
      justify-content: center;
      align-items: center;
      width: $button-size;
      height: $button-size;
      color: hsl(var(--text-dim));
      font-size: $font-size-icon-mobile;
      text-align: center;
      border-radius: $border-radius-round;
      cursor: pointer;
      .theme--dark & {
        color: hsl(var(--text-dim));
      }
      @media (hover:hover) {
        &:hover {
          color: hsl(var(--text));
        }
      }
      @media #{$mobile}, #{$tablet} {
        &:last-child {
          margin-left: .25rem;
          margin-right: .5rem;
        }
      }
      @media #{$desktop} {
        font-size: $font-size-icon;
        // &:not(:first-child) {
        //   margin-left: .25rem;
        // }
      }
      &.active {
        color: hsl(var(--text));
        &:not(.avoid-highlight) {
          background-color: hsl(var(--item));
        }
      }
    }
  }
  .sidemenu-actions {
    @media #{$mobile}, #{$tablet} {
      height: $menu-size-mobile;
    }
    @media #{$desktop} {
      flex-direction: row-reverse;
    }
  }
  .sidemenu-footer {
    justify-content: flex-start;
    .footer-links {
      display: flex;
      .footer-link {
        display: flex;
        align-items: center;
        margin-left: 1.5rem;
        font-size: .75rem;
        font-weight: bold;
        color: hsl(var(--text-dim));
        &:first-child {
          margin-left: .75rem;
        }
        &:hover {
          color: hsl(var(--text));
          svg {
            color: hsl(var(--text));
          }
        }
        svg {
          margin-right: .35rem;
          color: hsl(var(--text-faint));
          font-size: .65rem;
        }
      }
    }
  }
  .account-indicators {
    @include noselect;
    display: flex;
    align-items: center;
    padding: .2rem .4rem;
    margin-right: .75rem;
    background-color: hsl(var(--item));
    font-weight: bold;
    color: hsl(var(--text-dim));
    font-size: .7rem;
    letter-spacing: 1px;
    border-radius: .25rem;
    cursor: default;
    @media #{$mobile}, #{$tablet} {
      margin-right: 0.5rem;
      font-weight: bold;
    }
    @media #{$desktop} {
      margin-left: .25rem;
    }
    .icon {
      margin-right: 0.2rem;
      font-size: .75rem;
    }
  }
  .get-pro-btn {
    color: hsl(var(--text-dim));
    @media #{$desktop} {
      margin-right: .75rem;
    }
  }
  .account-dropdown {
    z-index: 7001;
    :deep(.action-btn) {
      svg {
        color: hsl(var(--text-dim));
      }
      @media (hover:hover) {
        &:hover {
          svg {
            color: hsl(var(--text)) !important;
          }
        }
      }
    }
  }
}
</style>
