<script setup lang="ts">
import { useStore } from 'vuex'

const route = useRoute()
const router = useRouter()
const store = useStore()
const colorMode = useColorMode()

let shortcutsInfoDialogTimeout: ReturnType<typeof setTimeout> | undefined
const globalShortcuts = [
  {
    trigger: ['shift', 'a'],
    title: 'Account',
    action: () => router.push('/account'),
  },
  {
    trigger: ['shift', 's'],
    title: 'Settings',
    action: () => router.push('/settings'),
  },
  {
    trigger: ['m'],
    title: 'Toggle menu',
    action: toggleSideMenu,
  },
  {
    trigger: { normal: ['shift', '*'], shift: ['*'] },
    title: 'Switch theme',
    info: 'Light → Dark → Auto',
    action: toggleDarkMode,
  },
]
const localShortcuts = computed(() => {
  const shortcuts = [
    {
      trigger: ['+'],
      title: 'Add new item',
    },
    {
      trigger: ['shift', 'f'],
      title: 'Search',
    },
    {
      trigger: ['shift', 'r'],
      title: 'Switch sorting mode',
    },
  ]
  switch (route.name) {
    case 'write':
      shortcuts.unshift({
        trigger: ['n'],
        title: 'New bullet',
      })
      break
  }
  return shortcuts
})

const profileSettings = computed(() => store.state.profileSettings)
const moduleShortcuts = computed(() => {
  if (profileSettings.value) {
    const shortcuts = [
      {
        trigger: ['1'],
        title: 'Write',
        action: () => router.push('/write'),
      },
      {
        trigger: ['2'],
        title: 'Journal',
        action: () => router.push('/journal'),
      },
      {
        trigger: ['3'],
        title: 'Reflect',
        hidden: !profileSettings.value.reflect_enabled,
        action: () => router.push('/reflect'),
      },
      {
        trigger: ['4'],
        title: 'Insights',
        action: () => router.push('/insights'),
      },
      {
        trigger: ['ctrl', '1'],
        title: 'Dreams',
        hidden: !profileSettings.value.dreams_enabled,
        action: () => router.push('/dreams'),
      },
      {
        trigger: ['ctrl', '2'],
        title: 'Highlights',
        action: () => router.push('/highlights'),
      },
      {
        trigger: ['ctrl', '3'],
        title: 'Tags',
        action: () => router.push('/tags'),
      },
      {
        trigger: ['ctrl', '4'],
        title: 'People',
        action: () => router.push('/people'),
      },
      {
        trigger: ['ctrl', '5'],
        title: 'Notes',
        hidden: !profileSettings.value.notes_enabled,
        action: () => router.push('/notes'),
      },
      {
        trigger: ['ctrl', '6'],
        title: 'Focus',
        hidden: !profileSettings.value.goals_enabled,
        action: () => router.push('/focus'),
      },
      {
        trigger: ['ctrl', '7'],
        title: 'Wisdom',
        hidden: !profileSettings.value.gems_enabled,
        action: () => router.push('/wisdom'),
      },
      {
        trigger: ['ctrl', '8'],
        title: 'Ideas',
        hidden: !profileSettings.value.ideas_enabled,
        action: () => router.push('/ideas'),
      },
    ]
    return shortcuts.filter(shortcut => !shortcut.hidden)
  }
  return []
})
const shortcuts = computed(() => globalShortcuts.concat(moduleShortcuts.value))

function toggleShortcutsInfoDialog() {
  // Delay the trigger by a few ms to avoid opening the dialog accidentally
  if (!store.getters.uix?.shortcutsInfoDialogOpen && !shortcutsInfoDialogTimeout) {
    shortcutsInfoDialogTimeout = setTimeout(() => {
      store.dispatch('updateUIx', { shortcutsInfoDialogOpen: true })
    }, 350)
  }
  else {
    clearTimeout(shortcutsInfoDialogTimeout)
    shortcutsInfoDialogTimeout = undefined
    closeShortcutsInfoDialog()
  }
}
function closeShortcutsInfoDialog() {
  store.dispatch('updateUIx', { shortcutsInfoDialogOpen: false })
}
function shortcutAction(shortcut: Shortcut) {
  if (shortcut.action) {
    shortcut.action()
  }
  // this.$toast.global.shortcut(shortcut)
}
function toggleSideMenu() {
  store.dispatch('updateUI', { sideMenuOpen: !store.getters.ui.sideMenuOpen })
}
function toggleDarkMode() {
  // store.dispatch('toggleDarkMode')
  switch (colorMode.preference) {
    case 'light':
      colorMode.preference = 'dark'
      break
    case 'dark':
      colorMode.preference = 'system'
      break
    default:
      colorMode.preference = 'light'
      break
  }
}
</script>

<template>
  <div class="shortcuts">
    <div class="hidden">
      <div
        v-shortkey.push="['i']"
        @shortkey="toggleShortcutsInfoDialog"
      />
      <div
        v-for="(shortcut, idx) in shortcuts"
        :key="idx"
        v-shortkey.once="shortcut.trigger"
        @shortkey="shortcutAction(shortcut)"
      />
    </div>
    <ShortcutDialogInfo
      v-if="store.getters.uix?.shortcutsInfoDialogOpen"
      :global-shortcuts="globalShortcuts"
      :local-shortcuts="localShortcuts"
      :module-shortcuts="moduleShortcuts"
      @close="closeShortcutsInfoDialog"
    />
  </div>
</template>
