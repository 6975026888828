<script setup lang="ts">
const props = withDefaults(defineProps<{
  icon?: string
  label: string
  color?: string
  colorBg?: string
}>(), {
  icon: '',
  color: 'hsl(var(--text))',
  colorBg: 'hsl(var(--element))',
})

const containerStyles = computed(() => ({
  '--color': props.color,
  '--color-bg': props.colorBg,
}))
const iconClasses = computed(() => ({
  'animate-spin': ['syncing', 'loading'].includes(props.icon),
}))
</script>

<template>
  <div class="indicator-container" :style="containerStyles">
    <div class="indicator-content">
      <div v-if="icon" class="icon-container">
        <Icon :name="icon" :class="iconClasses" />
      </div>
      <div class="label">
        {{ label }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.indicator-container {
  @include noselect;
  position: fixed;
  overflow: hidden;
  background-color: hsl(var(--base));
  font-size: small;
  z-index: 9995;
  @media #{$mobile}, #{$tablet} {
    bottom: calc(#{$menu-size-mobile} + 0.5px + env(safe-area-inset-bottom));
    left: 0;
    right: 0;
  }
  @media #{$desktop} {
    top: calc(1rem + env(safe-area-inset-top));
    right: 1rem;
    border-radius: $border-radius;
  }
  .indicator-content {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-bg);
    color: var(--color);
    @media #{$mobile}, #{$tablet} {
      padding: .35rem 1rem;
    }
    @media #{$desktop} {
      height: calc(#{$menu-size} - 2rem);
      padding: 0 1rem;
      border-radius: $border-radius;
    }
    .icon-container {
      display: flex;
      align-items: center;
      margin-right: .5rem;
    }
    .label {
      font-size: small;
      font-weight: bold;
    }
  }
}
</style>
