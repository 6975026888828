<script setup lang="ts">
const props = defineProps<{
  shortcut: Shortcut
}>()

const trigger = computed(() => {
  const triggerArray = Array.isArray(props.shortcut.trigger)
    ? props.shortcut.trigger
    : (props.shortcut.trigger.shift || props.shortcut.trigger.alt || props.shortcut.trigger.ctrl || [])
  return triggerArray.map((triggerEl: string) => {
    switch (triggerEl) {
      case 'shift':
        return '⇧'
      case 'ctrl':
        return '^'
    }
    return triggerEl
  })
})
</script>

<template>
  <div class="shortcut-item">
    <div class="triggers">
      <div
        v-for="(triggerElement, idx) in trigger"
        :key="idx"
        class="trigger"
      >
        {{ triggerElement }}
      </div>
    </div>
    <div class="content">
      <div
        class="title"
        v-html="shortcut.title"
      />
      <div
        v-if="shortcut.info"
        class="info"
        v-html="shortcut.info"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.shortcut-item {
  display: flex;
  align-items: center;
  .triggers {
    justify-content: space-around;
    align-items: center;
    width: 3.5rem;
    height: 2.5rem;
  }
  .title {
    font-size: .9rem;
  }
  .info {
    margin-top: .15rem;
    font-size: .7rem;
  }
}
</style>
