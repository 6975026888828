<script setup lang="ts">
import { useStore } from 'vuex'
import { version } from '~/package.json'

const store = useStore()
const online = useOnline()

const uix = computed(() => store.getters.uix)
const appVersion = version
</script>

<template>
  <div class="indicators">
    <transition name="indicator">
      <AppIndicator
        v-if="!online"
        icon="fa6-solid:wifi"
        label="Offline"
        color="hsl(var(--warning))"
        color-bg="var(--warning-bg)"
      />
      <AppIndicator
        v-else-if="uix.updating"
        icon="syncing"
        :label="`Updating to v${appVersion}`"
        color="hsl(var(--info))"
        color-bg="var(--info-bg)"
      />
      <AppIndicator
        v-else-if="uix.syncing"
        icon="syncing"
        label="Syncing..."
        color="hsl(var(--info))"
        color-bg="var(--info-bg)"
      />
      <AppIndicator
        v-else-if="uix.uploading"
        icon="loading"
        label="Uploading..."
      />
      <AppIndicator
        v-else-if="uix.indicator"
        :icon="uix.indicator.icon"
        :label="uix.indicator.label"
        :color="uix.indicator.type ? `hsl(var(--${uix.indicator.type}))` : undefined"
        :color-bg="uix.indicator.type ? `var(--${uix.indicator.type}-bg)` : undefined"
      />
    </transition>
  </div>
</template>
