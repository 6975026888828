<script setup lang="ts">
import { useStore } from 'vuex'

const store = useStore()

const stateClasses = computed(() => ({
  'mobile': store.getters.uix.mobile,
  'sidemenu-open': store.getters.ui.sideMenuOpen,
  'desktop-only': store.getters.uix.inputActive, // || store.getters.uix?.fullscreenMode,
}))
// const lookAheadEnabled = computed(() => store.getters.profile?.internal && store.getters.profileSettings?.planning_enabled)
const reflectEnabled = computed(() => store.getters.profileSettings?.reflect_enabled)
const insightsAvailable = computed(() => store.getters.pro || !store.getters.profile?.internal)
const menuIcon = computed(() => store.getters.ui.sideMenuOpen ? 'cross' : 'fa6-solid:bars')

function toggleSideMenu() {
  store.dispatch('updateUI', { sideMenuOpen: !store.getters.ui.sideMenuOpen })
}
</script>

<template>
  <div class="navigation" :class="stateClasses">
    <div class="menu-content">
      <AppNavItem
        to="/write"
        label="Today"
        title="Write"
        icon="write"
        class="write-item"
      />
      <AppNavItem
        to="/journal"
        label="Journal"
        :exact="true"
        title="Journal"
        icon="journal"
      />
      <!-- <AppNavItem
        v-if="lookAheadEnabled"
        to="/look-ahead"
        label="Look ahead"
        title="Look ahead"
        icon="look-ahead"
      /> -->
      <AppNavItem
        v-if="reflectEnabled"
        to="/reflect"
        label="Reflect"
        title="Reflect"
        icon="reflect"
      />
      <AppNavItem
        v-if="insightsAvailable"
        to="/insights"
        label="Insights"
        title="Insights"
        icon="insights"
      />
      <div class="menu-btn-container">
        <a class="menu-btn" @click="toggleSideMenu()">
          <Icon :name="menuIcon" />
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.navigation {
  @include noselect;
  position: fixed;
  right: 0;
  width: $menu-size-mobile;
  height: 0;
  z-index: 9001;
  .menu-btn {
    max-width: 4rem;
    border-bottom-left-radius: $border-radius-xl;
    border-bottom-right-radius: $border-radius-xl;
  }
  .menu-content {
    position: fixed;
    width: 100vw;
    height: #{$menu-size-mobile};
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: hsl(var(--base));
    box-sizing: content-box;
    & > * {
      flex: 1 0;
      max-width: $content-max-width * 0.2; // orig: * 0.25
      :deep(svg) {
        font-size: 1.25rem;
      }
      &:first-child {
        margin-left: 1.25rem;
      }
      &:last-child {
        margin-right: 1.25rem;
      }
    }
    .menu-btn-container {
      flex: 1 0;
      display: flex;
      height: $menu-size-mobile;
      margin-bottom: 0 !important;
      justify-content: center;
      align-items: flex-start;
      align-self: center;
      text-align: center;
      .menu-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% - 2rem);
        min-width: 3rem;
        height: calc(100% - .5rem);
      }
    }
  }
  .menu-content {
    border-top: 1px solid hsl(var(--line));
  }
  &.sidemenu-open {
    .menu-content {
      .menu-btn-container {
        .menu-btn {
          max-width: 5rem;
          margin-top: -1px;
          background-color: hsl(var(--surface));
          color: hsl(var(--text));
          border: 1px solid hsl(var(--line));
          border-top: 1px solid hsl(var(--surface));
          svg {
            margin-top: calc(2px + .25rem);
          }
        }
      }
    }
  }
  &:not(.sidemenu-open) {
    .menu-content {
      .menu-btn-container {
        .menu-btn {
          color: hsl(var(--text-faint)) !important;
          svg {
            margin-top: .5rem;
          }
        }
      }
    }
  }
}
// Fix issue with TWA.
// When the app opens it attaches a ?... to the URL so that it doesn't match
// exactly. Solution is to only for not-exact matches on the write item bc
// otherwise there are problems with not exact highlighting elsewhere.
:deep(.write-item) {
  a.active {
    .icon,
    .label {
      color: hsl(var(--text));
    }
  }
}
</style>
