<script setup lang="ts">
import { useStore } from 'vuex'

const store = useStore()

const actionQueueDialogOpen = ref(false)
const conflictsDialogOpen = ref(false)

const sync = computed(() => store.getters.sync)
const developer = computed(() => !!store.getters.profile?.developer)
// conflicts(): SyncConflict[] | undefined {
//   return this.sync?.conflicts || []
// },
// showConflictsDialog(): SyncConflict[] | undefined {
//   return this.$nuxt.isOnline && this.sync?.conflicts || undefined
// }

function resolveConflicts() {
  store.dispatch('resolveConflicts')
}

// watch: {
//   conflicts(val): void {
//     this.conflictsDialogOpen = !!val.length
//   },
// },
// methods: {
//   ...mapActions([
//     'resolveConflicts',
//   ]),
// },
</script>

<template>
  <div class="dialogs">
    <template v-if="developer">
      <AppDialog
        v-if="actionQueueDialogOpen"
        title="Action queue"
        @close="actionQueueDialogOpen = false"
      >
        <p class="sync-info">
          Action sync enabled: {{ store.getters.profileSettings?.action_sync_enabled }}<br>
          {{ sync.id }}<br>
          {{ sync.sts }}<br>
          [{{ sync.pushState }}]
        </p>
        <AppActionQueue />
      </AppDialog>
      <div class="hidden">
        <div
          v-shortkey.once="['shift', 'x']"
          @shortkey="actionQueueDialogOpen = true"
        />
      </div>
    </template>
    <AppDialog
      v-if="conflictsDialogOpen"
      title="Syncing conflicts"
      :footer="true"
      @close="conflictsDialogOpen = false"
    >
      <p class="conflicts-info">
        There were {{ sync.conflicts.length }} conflict/s while syncing.
        Another device updated/deleted the same objects. Click "resolve conflicts"
        to fetch the updates.
      </p>
      <template #footer>
        <div class="dialog-actions">
          <a
            class="button action"
            @click="resolveConflicts()"
          >
            Resolve conflicts
          </a>
        </div>
      </template>
    </AppDialog>
  </div>
</template>

<style lang="scss" scoped>
.sync-info {
  margin-top: 0;
  line-height: 1.3;
  font-size: .8rem;
}
.conflicts-info {
  margin-top: 0;
}
</style>
