<script setup lang="ts">
withDefaults(defineProps<{
  globalShortcuts?: Shortcut[]
  localShortcuts?: Shortcut[]
  moduleShortcuts?: Shortcut[]
}>(), {
  globalShortcuts: () => [],
  localShortcuts: () => [],
  moduleShortcuts: () => [],
})
defineEmits(['close'])

const infoShortcut = {
  trigger: ['i'],
  title: 'Overview',
  info: 'Press and hold to glance at the shortcuts overview.',
}
</script>

<template>
  <AppDialog
    title="Shortcuts"
    width="780px"
    @close="$emit('close')"
  >
    <div class="info">
      <ShortcutInfoItem :shortcut="infoShortcut" />
    </div>
    <div class="shortcuts">
      <div class="left">
        <h4>Global</h4>
        <ShortcutInfoItem
          v-for="(shortcut, idx) in globalShortcuts"
          :key="'g' + idx"
          :shortcut="shortcut"
        />
        <h4>Local</h4>
        <div class="local-info">
          Local shortcuts only work on pages where their specific functionality is available.
        </div>
        <ShortcutInfoItem
          v-for="(shortcut, idx) in localShortcuts"
          :key="'l' + idx"
          :shortcut="shortcut"
        />
      </div>
      <div class="right">
        <h4>Modules</h4>
        <ShortcutInfoItem
          v-for="(shortcut, idx) in moduleShortcuts"
          :key="'m' + idx"
          :shortcut="shortcut"
        />
      </div>
    </div>
  </AppDialog>
</template>

<style lang="scss" scoped>
.shortcuts {
  display: flex;
  h4 {
    margin: 2rem 0 1rem;
  }
  .left,
  .right {
    flex: 0 0 50%;
    box-sizing: border-box;
  }
  .left {
    padding-right: 2rem;
  }
  .local-info {
    font-size: .75rem;
    margin-bottom: 1rem;
  }
}
</style>
